import React from 'react';

const Rezerwacja = () => {
  return (
    <div>
        <section class="rezerwacja">
          <p>W celu uzgodnienia szczegółów pobytu i dokonania wszystkich potrzebnych formalności prosimy o kontakt z nami telefonicznie. </p>
          <br></br>
          <p>Rezerwacja przelewem na konto bankowe w postaci zadatku o wysokości 30% kwoty na konto o numerze: <br></br>
          <b>89 1050 1083 1000 0092 1687 7176</b></p>
          <br></br>
        </section>
    </div>
  );
};

export default Rezerwacja;