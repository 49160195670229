import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Onas from './Components/Onas';
import Aktualnosci from './Components/Aktualnosci';
import Kontakt from './Components/Kontakt';
import Lokalizacja from './Components/Lokalizacja';
import Rezerwacja from './Components/Rezerwacja';
import Regulamin from './Components/Regulamin';
import Galeria from './Components/Galeria';
import Cennik from './Components/Cennik';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/*" element={<Onas />} />
        <Route path="/onas" element={<Onas />} />
        <Route path="/aktualnosci" element={<Aktualnosci />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/lokalizacja" element={<Lokalizacja />} />
        <Route path="/rezerwacja" element={<Rezerwacja />} />
        <Route path="/galeria" element={<Galeria />} />
        <Route path="/cennik" element={<Cennik />} />
        <Route path="/regulamin" element={<Regulamin />} />
      </Routes>
    </Router>
  );
}

export default App;