import React from 'react';

const Aktualnosci = () => {
  return (
    <section id="facebook" style={{ maxWidth: '100%', overflow: 'hidden' }}>
      <p>
        Odwiedź nas na{' '}
        <a href="https://www.facebook.com/profile.php?id=100095454220290">Facebook'u</a> aby być na
        bieżąco!
        <br></br>I spraw aby już Cię nic nie ominęło!</p>
      <br />
      <div
        style={{
          width: '100%',
          maxWidth: '500px', // Maksymalna szerokość dla iframe
          overflow: 'hidden',
          margin: '0 auto', // Wycentrowanie zawartości
        }}
      >
        <iframe
          title="Facebook Page"
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100095454220290&tabs=timeline%2C%20events%2C%20messages&width=700&height=800&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          width="100%"
          height="800"
          scrolling="yes"
          frameBorder="0"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
    </section>
  );
};

export default Aktualnosci;
