import React from 'react';
import Rezerwacja from './Rezerwacja';

const Kontakt = () => {
  return (
    <section className="kontakt">
      <Rezerwacja />
      Skontaktuj się z nami po więcej informacji i w celu uzgodnienia szczegółów Twojegu pobytu. <br /><br />
      <b>Numer telefonu:</b> <br />
      <h3>+48 508 959 408 <br /></h3>
      <br></br>
      <b>Kontakt e-mail:</b> <br />
      domkinasnowancu@gmail.com <br />
      <br />
      <b>
        <a href="https://www.facebook.com/profile.php?id=100095454220290" target="_blank" rel="noopener noreferrer">
          Facebook
        </a>{' '}
      </b>
      <br></br><br></br>
      <p>
        <b>Odwiedź nas pod adresem:</b>
        <br />
        Brenna 43-438 <br />
        ul. Snowaniec 56
      </p>
      <br></br><br></br>
      <p>
        Numer konta bankowego: <br></br>
        <b>89 1050 1083 1000 0092 1687 7176</b>
      </p>
      <br />
    </section>
  );
};

export default Kontakt;
