import './css/galeria.css';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Galeria = () => {
  // Utwórz kontekst dla folderu ze zdjęciami
  const imagesContext = require.context('./zdjecia-snowaniec2', false, /\.(png|jpe?g|svg)$/);

  // Pobierz nazwy wszystkich plików ze zdjęciami
  const imageFileNames = imagesContext.keys();

  // Utwórz tablicę obiektów zdjęć
  const zdjecia = imageFileNames.map((fileName, index) => ({
    src: imagesContext(fileName),
    alt: `DomkiNaSnowancu ${index + 1}`,
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleImageClick = (index) => {
    setPhotoIndex(index);
    openModal();
  };

  const openModal = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const closeModal = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <div>
      {zdjecia.map((zdjecie, index) => (
        <div className='photo' key={index}>
          <img
            src={zdjecie.src}
            alt={zdjecie.alt}
            onClick={() => handleImageClick(index)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ))}
      {isOpen && (
        <Lightbox
          mainSrc={zdjecia[photoIndex].src}
          nextSrc={zdjecia[(photoIndex + 1) % zdjecia.length].src}
          prevSrc={zdjecia[(photoIndex + zdjecia.length - 1) % zdjecia.length].src}
          onCloseRequest={() => closeModal()}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + zdjecia.length - 1) % zdjecia.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % zdjecia.length)}
        />
      )}
    </div>
  );
};

export default Galeria;
