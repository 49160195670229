import React from 'react';

const Lokalizacja = () => {
  return (
    <div>
      <p>
        <b>Odwiedź nas pod adresem:</b>
        <br />
        Brenna 43-438 <br />
        ul. Snowaniec 56
      </p>
      <br />
      <section id="map">
        <center>
          <iframe
            title="Mapa"
            width="70%"
            height="550"
            style={{ border: '2px solid white' }}
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJk1-TIJseFEcR1D81zov91ok&key=AIzaSyBawYgn4EhBG02KDkNu3x9vbpecf-Goch8"
          ></iframe>
        </center>
      </section>
    </div>
  );
};

export default Lokalizacja;
