import React from 'react';
import "./css/onas.css"

const Onas = () => {
  return (
    <div>
    <div className="main">
      <section id="info">
        Odkryj urok górskich "Domków na Snowańcu" w miejscowości Brenna! Zapraszamy do naszego magicznego zakątka, gdzie marzenia o relaksie i oddechu od codzienności stają się rzeczywistością.<br /><br></br>
        Zapomnij o troskach i pośpiechu! W środku przyrody, otuleni górską ciszą, odnajdziesz harmonię i spokój, który w pełni pozwoli Ci zrelaksować się. Nasze domki są w pełni wyposażone, by zapewnić Ci niezapomniany pobyt. W dzień zachwycaj się urokami przyrody, a wieczorem oddaj się błogiemu odpoczynkowi przy kominku.
        <br /><br />
        Wybierając nas, wybierasz idealne miejsce na relaks, wyciszenie i ucieczkę od zgiełku codzienności. Rozkoszuj się górską ciszą, delektuj pięknem otaczającej przyrody i oddaj się w pełni wspaniałym widokom, które zapierają dech w piersiach.
        <br /><br />
        Z nami świetny odpoczynek to pewniak, skontaktuj się z nami aby uzgodnić Twoją wizyte i spędzić świetnie czas. Sprawdź na własnej skórze co oferują Domki na Snowańcu. Czekamy na ciebie!
        <hr></hr>
      </section>
      <br></br>
      <section className="zdjecia">
        <img src='/resources/images/zdjecia_snowaniec/zdjecie1.jpg' alt='zdjecie1'></img>
        <img src='/resources/images/zdjecia_snowaniec/zdjecie2.jpg' alt='zdjecie2'></img>
      </section>
      <section id="atrakcje">
        <hr></hr>
        <b>Lokalizacja "Domków na Snowańcu" pozwala na spędzanie czasu na wiele sposobów. Poznaj atrakcje, które czekają na Ciebie w Brennej i bliskiej okolicy.</b><br /><br />
          <ol>
            <li><b>Poznaj piękno Beskidów, dla miłośników gór i natury: </b></li>
            <ul>
              <li>🏞️ Szczyt Równica - około 884 m n.p.m.</li>
              <li>🌄 Szczyt Trzy Kopce - około 850 m n.p.m.</li>
              <li>🏔️ Szczyt Błatnia - około 867 m n.p.m.</li>
              <li>🌲 Szczyt Malinowska Skała - około 964 m n.p.m</li>
            </ul>
            <li><b>Dla lubiących aktywny wypoczynek: </b></li>
            <ul>
              <li>🚴 Ścieżki rowerowe</li>
              <li>🎿 Ośrodki narciarskie</li>
              <li>🏊‍♂️ Baseny i rzeki</li>
              <li>🏔️ Wędrówki górskie</li>
              <li>🏇 Jazda konno</li>
            </ul>
            <li><b>Brenna oferuje również bogatą regionalną kulture: </b></li>
            <ul>
              <li>🍲 Tradycyjna kuchnia</li>
              <li>🍷 Winnice</li>
              <li>💃 Taniec i muzyka regionalna</li>
              <li>🏰 Zabytki</li>
              <li>🎉 Specjalne wydarzenia</li>
              <li>🌄 Wyjątkowe tradycje i kultura</li>
            </ul>
            <li><b>Atrakcje w pobliżu:</b></li>
            <ul>
              <li>Chlebowa Chata - 3,1 km</li>
              <li>Rzeka Brennica - 700 m</li>
              <li>Centrum - 3,0 km</li>
              <li>Wyciąg narciarski "Ski Dolina Brenna" - 8,2 km</li>
              <li>Kozia Zagroda - 4,0 km</li>
              <li>Dziki wodospad - 7,0 km</li>
              <li>Beskidzki Dom Zielin "Przytulia" - 3,8 km</li>
              <li>Trasy biegowe - 8,0 km</li>
            </ul>
          </ol>
            <p><b>I wiele więcej niespodzianek, które kryje w sobie Brenna i Domki na Snowańcu!</b></p>
          <hr></hr>
      </section>
      <section className="zdjecia">
        <img src='/resources/images/zdjecia_snowaniec/zdjecie4.jpg' alt='zdjecie4'></img>
        <img src='/resources/images/zdjecia_snowaniec/zdjecie3.jpg' alt='zdjecie3'></img>
      </section>
      <br></br>
      <p>Każdy domek jest w pełni wyposażony, dzięki czemu nic oprócz wypoczynku nie będzie Cie interesować. Domki mają dostęp do Wi-Fi oraz telewizor.<br></br><br></br></p>
      <section id='info'>
        <b>Snowaniec</b> - potok, Potok płynie w pasmie Równicy w Beskidzie Śląskim na terenie Brennej. Jego źródła znajdują się na wysokości ok. 600 m n.p.m. na północno-wschodnich stokach Równicy. Spływa dość głęboką dolinką w kierunku północno-wschodnim i w Brennej Spalonej na wysokości ok. 370 m n.p.m. wpada do Brennicy. W dolnej części dolinki potoku położone jest osiedle Brennej o nazwie Snowaniec.
        <br /><br />
      </section>
    </div>
    </div>
  );
};

export default Onas;

