import React from "react";
import './css/regulamin.css'

const Regulamin = () =>{
    return(
        <div className="regulamin">
          <h2>Regulamin agroturystyki "Domki na Snowańcu"</h2>
          <br></br>
          <ol>
            <li>Rezerwacja pobytu równoznaczna jest z akceptacją regulaminu.</li>
            <li>Do korzystania z domku mają prawo osoby wyłącznie zameldowane. Brak możliwości pobytu w domku osób trzecich, niezameldowanych.</li>
            <li>Rezerwacja odbywa się telefonicznie pod numerem +48 508 959 408, a płatność jest realizowana gotówką w momencie przyjazdu u gospodarza przy wcześniejszym opłacaniu zadatku o wysokości 30% kwoty.</li>
            <li>W momencie zakwaterowania i przy opłacaniu pobytu, pobierana jest kaucja zwrotna w wysokości 300zł.</li>
            <li>Na terenie gospodarstwa obowiązuje cisza nocna w godzinach 22:00-6:00</li>
            <li>Na terenie domków zabrania się:</li>
            <ol type="I">
              <li>zakłócania spokoju innym osobom przebywającym na terenie gospodarstwa.</li>
              <li>organizowana imprez (wyjątkiem są te uzgodnione wcześniej z gospodarzem)</li>
              <li>palenia wszelkiego rodzaju papierosów</li>
              <li>pobytu zwierząt bez zgody gospodarza</li>
            </ol>
            <li>Najemca ponosi odpowiedzialność materialną za wszelkiego rodzaju uszkodzenia powstałego z jego winy lub osób przebywających na terenie domku w okresie trwania pobytu.</li>
            <li>Nieprzestrzaganie regulaminu wiąże się z opuszczeniem domków i anulowaniem pobytu bez prawa do możliwości zwrotu pieniędzy i odszkodowań.</li>
            <li>Brak możliwości wynajmu na 1 dobę</li>
            <li>Okresy świąteczne - Wielkanoc, Boże Narodzenie, Sylwester - ceny ustalane są indywidulanie (minimum 2 doby)</li>
            <li>Ceny są uzależnione od okresów oraz długości wynajmu (od 450zł za dobę)</li>
            <li>Gospodarz zastrzega sobie prawo do odmowy zakwaterowania wobec osób będących pod widocznym wpływem alkoholu, środków odurzających oraz agresywnych.</li>
            <li>Domki są przekazywane gościom w użytkowanie i odbierane przy wyjeździe.</li>
            <li>Domki są zawsze posprzątane i takie należy pozostawić po swoim pobycie.</li>
            <li>Za przedmioty pozostawione w domku i na jego terenie gospodarz nie ponosi odpowiedzialności.</li>
            <li>Miejsce postojowe przy domku jest miejscem niestrzeżonym, właściciel nie ponosi odpowiedzialności za utratę, uszkodzenia pojazdu.</li>
            <li>Gospodarz nie ponosi odpowiedzialności za przejściowe niedogodności występujące nie z jego winy np. Brak prądu, internetu, telewizji.</li>
            <li>Odpowiedzialność za pobyt dzieci na terenie gospodarstwa ponoszą rodzice lub opiekunowie.</li>
          </ol>
        </div>

    );
}

export default Regulamin;

    
    
    



