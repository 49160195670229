import React from 'react';
import "./css/cennik.css";




const Cennik = () => {
  return (
    
    <div>
      <p>W celu dokonania rezerwacji prosimy o kontakt telefonicznie <a href='/kontakt'>+48 508 959 408</a></p>
      <br></br>
      <h3>Cennik:</h3>
      <h4>Cena wynajmu domku <u>od 450zł</u> za dobe. <br></br>Ceny ustalane w zależności od okresu wynajmu.<br></br> <u>Minimalny</u> okres wynajmu domku to 2 doby.</h4>
      <hr></hr>
      <br></br><br></br>
      <h3>Płatność gotówką w dniu przyjazdu u gospodarza <br></br>(+ pobierana jest kaucja w kwocie 300zł, oddawana w dniu wyjazdu)</h3>
      <br></br>
      <p>W naszym gospodarstwie obecnie do wynajęcie jest kilka domków</p>
      <p>Okresy świąteczne - Wielkanoc, Boże Narodzenie, Sylwester - ceny ustalane są indywidulanie (minimum 2 doby - brak możliwości wynajmu na 1 dobę)</p>
      <p>Ceny są uzależnione od okresów oraz długości wynajmu (od 450zł za dobę)</p>
      <p>Przyjazd z pupilem możliwy przy wcześniejszym uzgodnieniu z gospodarzem</p>
    <br></br>
      <p>Rezerwacja przelewem na konto bankowe w postaci zadatku o wysokości 30% kwoty na konto o numerze: <br></br>
          <b>89 1050 1083 1000 0092 1687 7176</b></p>
          <br></br>
      <p><a href="/regulamin">Regulamin</a>.</p>
    </div>
  );
};

export default Cennik;
